
import { Component, Prop, Vue } from "vue-property-decorator";

type Tile = {
  id: number;
};

@Component
export default class NotrufComponent extends Vue {
  @Prop(Object) readonly tile!: Tile;

  localTile: null | Tile = null;

  get userData() {
    return this.$store.getters["profile/data"];
  }

  created() {
    this.$store.dispatch("app/setTile", this.tile.id).then(({ data }) => {
      this.localTile = this.hasRole("guest") ? { ...this.tile } : { ...data };
    });
  }

  callMedicall() {
    window.open("tel:0848111811");
  }

  hasRole(role: string): boolean {
    return this.userData?.roles.some(r => r.slug == role);
  }
}
